import React from 'react';
import BigText from '../../components/big-text';
import DotNav from '../../components/dot-nav';
import AAAIcon from '../../components/icons/aaa-icon';
import Logo from '../../components/icons/finswash';
import { NavItem } from '../../utils/types';
import { useMedia } from '../../utils/hooks';
import './about-us.css';

export interface AboutUsProps {
  number: string;
  style?: React.CSSProperties;
  navItems: Array<NavItem>;
  onNavItemSelected: (index: number) => void;
}

const AboutUs: React.FC<AboutUsProps> = ({
  number,
  style,
  navItems,
  onNavItemSelected
}) => {
  const options = useMedia(
    ['(max-width: 768px)', '(max-width: 500px)'],
    [
      {
        base: {},
        number: {
          left: '-60px'
        },
        FINTASTIC: {
          paddingLeft: 200,
          fontSize: '150px'
        }
      },
      {
        base: {},
        number: {
          left: '-20px'
        },
        FINTASTIC: {
          paddingLeft: 100,
          fontSize: '150px'
        }
      }
    ],
    {
      base: {
        minHeight: '768px'
      },
      number: {
        left: '-100px'
      },
      FINTASTIC: {
        paddingLeft: 250,
        fontSize: '150px'
      }
    }
  );

  return (
    <div id="AboutUs" style={{ ...(style || {}), ...options.base }}>
      <div className="AboutUs-background-text">
        <BigText
          value={number}
          thin={true}
          white={true}
          style={{ position: 'absolute', top: 0, ...options.number }}
        />
        <div className="AboutUs-background-text-bottom">
          <BigText
            value="FINS CAR WASH"
            thin={true}
            white={true}
            style={{ ...options.FINTASTIC }}
          />
        </div>
      </div>
      <div className="AboutUs-content">
        <Logo />
        <div className="AboutUs-title">
          Experience a <br />
          <span className="AboutUs-title__standout">fintastic</span> wash
        </div>
        <div className="AboutUs-text">
          <DotNav
            selected={parseInt(number, 10) - 1}
            items={navItems}
            vertical={true}
            onChange={onNavItemSelected}
            style={{ marginLeft: '-30px', position: 'absolute' }}
          />
          <div className="AboutUs-text-body">
            <span className="introduction">
              Fins Car Wash is for car lovers--no matter the make, model or age
              of your car. Our motto is <b>FAST, FUN and FINTASTIC.</b>
            </span>
            <br />
            <br />
            FAST because we know your time is valuable and that you want to
            spend as much of it on the open road as possible. FUN because you
            have lots of things in your life to be serious about, but a
            two-minute car wash shouldn&apos;t be one of them. FINTASTIC
            because, well, it just is!
          </div>
        </div>
      </div>
      <div className="AboutUs__AAA-tag" style={{ display: 'none'}}>
        <AAAIcon />
      </div>
    </div>
  );
};

export default AboutUs;
