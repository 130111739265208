import React from 'react';
import classnames from 'classnames';
import CloseIcon from '../icons/close';

import './section-slide-over.css';

export interface SectionSlideOverProps {
  open?: boolean;
  zIndex?: number;
  style?: React.CSSProperties;
  onClose: VoidFunction;
}

const SectionCloseButton: React.FC<{ onClick: VoidFunction }> = ({
  onClick
}) => (
  <div className="SectionSlideOver-close" role="button" onClick={onClick}>
    <CloseIcon height={22} />
  </div>
);

const SectionSlideOver: React.FC<SectionSlideOverProps> = ({
  style = {},
  zIndex = 2,
  open,
  onClose,
  children
}) => {
  const className = classnames({
    SectionSlideOver: true,
    'SectionSlideOver__is-open': open
  });
  return (
    <div style={{ zIndex: zIndex + 1 }} className={className}>
      <div className="SectionSlideOver-content" style={style}>
        <SectionCloseButton onClick={onClose} />
        {children}
      </div>
    </div>
  );
};

export default SectionSlideOver;
