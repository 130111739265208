import React from 'react';
import { IconProps } from './types';

const TopWashClubIcon: React.FC<IconProps> = ({
  color = '#92be28',
  secondaryColor = 'var(--pallet-white)',
  height = 68.094,
  width = 163.655,
  style
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 163.655 68.094"
      role="img"
    >
      <g transform="translate(590 -4439)">
        <g transform="translate(-590 4439)">
          <path
            d="M207.021,177.628a16.5,16.5,0,0,1-.353,1.95l65.157-3.773-4.309,36.812-155,8.975,4.31-36.812,58.454-3.385a16.433,16.433,0,0,1-.576-1.9l-59.6,3.451-4.764,40.7,158.891-9.2L274,173.75Z"
            transform="translate(-110.344 -155.553)"
            fill={color}
          />
          <g transform="translate(66.056 0)">
            <path
              d="M288.542,175.459l-.569-.97c-.049-.091-.106-.188-.17-.29h0c-.459-.737-1.223-1.7-1.327-1.847-.067-.1-.775-1.079-.83-1.152a23.25,23.25,0,0,0-11.232-8.2c-.412-.156-.791.332-.644.831a41.387,41.387,0,0,1,1.2,4.809,46.677,46.677,0,0,1,.28,5.836l-.065,1.126a.475.475,0,0,0,.41.544h12.665C288.544,176.149,288.711,175.746,288.542,175.459Zm-1.859-.243H285.2a.149.149,0,0,1-.135-.109,20.758,20.758,0,0,0-6.154-8.568c-.039-.031-.005-.1.037-.079a22.649,22.649,0,0,1,7.858,8.47C286.872,175.051,286.8,175.216,286.683,175.216Z"
              transform="translate(-265.508 -149.69)"
              fill={secondaryColor}
            />
            <path
              d="M277.985,147.354a2.444,2.444,0,1,0-4.08-1.886,14.317,14.317,0,0,0-16.137,21.2,2.552,2.552,0,0,0,3.318,3.763,14.315,14.315,0,1,0,16.9-23.08Zm-7.977,24.159a12.212,12.212,0,0,1-7.725-2.746,2.555,2.555,0,0,0-2.5-3.081c-.069,0-.137.005-.2.01a12.267,12.267,0,1,1,10.43,5.817Z"
              transform="translate(-255.692 -138.876)"
              fill={color}
            />
            <circle
              cx="1.551"
              cy="1.551"
              r="1.551"
              transform="translate(16.446)"
              fill={color}
            />
            <path
              d="M310.827,204.8a1.431,1.431,0,1,0,1.431,1.431A1.431,1.431,0,0,0,310.827,204.8Z"
              transform="translate(-284.906 -172.441)"
              fill={color}
            />
          </g>
        </g>
        <text
          id="FINS_WASH_CLUB"
          data-name="FINS WASH CLUB"
          transform="translate(-576.303 4477.047) rotate(-3)"
          fill={secondaryColor}
          fontSize="18"
          fontFamily="Cubano-Regular, Cubano"
        >
          <tspan x="0.824" y="18">
            FINS WASH CLUB
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default TopWashClubIcon;
