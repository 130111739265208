import React, { forwardRef } from 'react';
import Slider from 'react-slick';
import classnames from 'classnames';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './horizontal-scroll.css';

export interface HorizontalScrollProps {
  children?: any;
  slidesToShow?: number;
  centerMode?: boolean;
  infinite?: boolean;
  autoplay?: boolean;
  dark?: boolean;
  variableWidth?: boolean;
  ref?: any;
}

const SimpleArrow: React.FC<any> = ({ className, style, onClick, dark }) => {
  return (
    <div
      className={classnames({
        'HorizontalScroll-arrow': true,
        'HorizontalScroll-arrow__dark': dark,
        [className]: typeof className === 'string'
      })}
      style={{
        ...style,
        zIndex: 10,
        width: 'var(--horizontal-scroll-arrow-size)',
        height: 'var(--horizontal-scroll-arrow-size)'
      }}
      onClick={onClick}
    />
  );
};

const HorizontalScroll: React.FC<HorizontalScrollProps> = (
  { children, dark = false, centerMode = true, infinite = true, ...options },
  ref
) => {
  const settings = {
    dots: true,
    swipe: true,
    arrows: true,
    swipeToSlide: true,
    drag: true,
    speed: 500,
    autoplaySpeed: 2000,
    nextArrow: <SimpleArrow dark={dark} />,
    prevArrow: <SimpleArrow dark={dark} />,
    centerMode,
    infinite,
    ...options
  };
  return (
    <Slider ref={ref} {...settings}>
      {children}
    </Slider>
  );
};

export default forwardRef(HorizontalScroll);
