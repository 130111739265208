import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { NavItem } from '../../utils/types';
import { useMedia } from '../../utils/hooks';
import TopWashClubIcon from '../../components/icons/top-wash-club';
import BigText from '../../components/big-text';
import DotNav from '../../components/dot-nav';
import Card from '../../components/card';
import Button from '../../components/button';
import './top-wash-club.css';

export interface TopWashClubProps {
  number: string;
  style?: React.CSSProperties;
  navItems: Array<NavItem>;
  onNavItemSelected: (index: number) => void;
}

const TopWashClub: React.FC<TopWashClubProps> = ({
  number,
  style,
  navItems,
  onNavItemSelected
}) => {
  const options = useMedia(
    ['(max-width: 770px)', '(max-width: 414px)'],
    [
      {
        number: {
          left: '-20px'
        }
      },
      {
        number: {
          left: '-20px'
        }
      }
    ],
    {
      number: {
        left: '-100px'
      }
    }
  );
  const backgroundImage = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "top-wash-club-background-0.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const handleGetStarted = () => {
    // e.preventDefault();
    // e.stopPropagation();
    // TODO handle get started
    history.pushState({}, 'FinsWash', `/join-wash-club`);
    window.location.reload();
  };

  const handleManageMembership = () => {
    // e.preventDefault();
    // e.stopPropagation();
    // TODO handle get started
    history.pushState({}, 'FinsWash Manage Membership', `/manage-membership`);
    window.location.reload();
  };

  return (
    <div id="TopWashClub" style={style}>
      <div className="TopWashClub-background-image-container">
        <Img
          fluid={backgroundImage.placeholderImage.childImageSharp.fluid}
          className="TopWashClub-background-image"
        />
      </div>
      <div className="TopWashClub-background-text">
        <BigText
          value={number}
          white={true}
          style={{
            position: 'absolute',
            top: 0,
            opacity: 0.3,
            ...options.number
          }}
        />
      </div>
      <div className="TopWashClub-content">
        <div className="TopWashClub-main-content">
          <div className="TopWashClub-title">
            <div className="TopWashClub-title-content">
              <div className="TopWashClub__mobile-icon">
                <TopWashClubIcon />
              </div>
              <div className="TopWashClub-title-content-top">
                UNLIMITED WASHES
              </div>
              <div className="TopWashClub-title-content-main">
                Unbeatable Shine
              </div>
              <DotNav
                selected={parseInt(number, 10) - 1}
                items={navItems}
                onChange={onNavItemSelected}
                style={{ marginTop: 'var(--spacing)' }}
              />
            </div>
          </div>
        </div>
        <div className="TopWashClub-sub-content">
          <Card topperSize="large" topperColor="var(--pallet-green)">
            <div className="TopWashClub-lgs">
              <div className="TopWashClub-lgs-header">Join Fins Wash Club</div>
              <div className="TopWashClub-lgs-body">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '24px 0'
                  }}
                >
                  <TopWashClubIcon secondaryColor="currentColor" />
                </div>
                Join Fins Wash Club and enjoy a wash as often as you like. We
                offer one low monthly price with convenient automatic billing.
                You may purchase and use your plan at any Fins Car Wash
                location.
                <br />
                <br />
                <span>How Does Fins Wash Club Work?</span>
                Fins uses license plate recognition technology to identify your vehicle in our system.
                One car per Wash Club Membership is
                permitted. Monthly billing occurs automatically each month on
                the corresponding day to the original purchase date. No
                contracts or cancellation fees.
              </div>
              <Button
                text="JOIN UNLIMITED NOW"
                style={{ marginTop: 32, marginBottom: 16, display: 'none' }}
                onClick={handleGetStarted}
                className="ctaFJoinUnlimited"
              />
              <Button
                text="MANAGE MEMBERSHIP"
                style={{ marginTop: 32, marginBottom: 16, display: 'none' }}
                onClick={handleManageMembership}
                className="ctaFManageMembership"
              />
              {/* TODO: Will be a form at a later date
              
              <form
                className="TopWashClub-lgs-form"
                onSubmit={handleGetStarted}
              >
                <label htmlFor="FirstName">First Name</label>
                <input id="FirstName"></input>
                <label htmlFor="LastName">Last Name</label>
                <input id="LastName"></input>
                <label htmlFor="Email">Email</label>
                <input id="Email"></input>
                <label htmlFor="Mobile">Mobile</label>
                <input id="Mobile"></input>
                <label htmlFor="Zip Code">Zip Code</label>
                <input id="Zip Code"></input>
                <Button
                  submit={true}
                  text="LET'S GET STARTED"
                  style={{ marginTop: 40 }}
                />
              </form> */}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default TopWashClub;
