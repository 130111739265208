export const MAP_OPTIONS = {
  on: true,
  loaded: true,
  center: { lat: 34.8207, lng: -82.2827 } as google.maps.LatLngLiteral,
  type: 'roadmap',
  minZoom: 1,
  style: 'custom',
  styleJSON: [
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#444444' }]
    },
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [{ hue: '#ff0000' }]
    },
    {
      featureType: 'landscape',
      elementType: 'geometry.fill',
      stylers: [{ color: '#e9efd3' }]
    },
    {
      featureType: 'landscape',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#87a244' }]
    },
    {
      featureType: 'landscape',
      elementType: 'labels.text',
      stylers: [{ color: '#87a244' }]
    },
    {
      featureType: 'landscape',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#87a244' }]
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [{ saturation: -100 }, { lightness: 45 }]
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [{ visibility: 'simplified' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [{ color: '#cbd89e' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#85a142' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text',
      stylers: [{ color: '#85a142' }, { visibility: 'off' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.icon',
      stylers: [
        { visibility: 'on' },
        { hue: '#b4ff00' },
        { saturation: '54' },
        { lightness: '-13' },
        { gamma: '0.50' },
        { weight: '1.59' }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [{ color: '#c3dabe' }, { visibility: 'on' }]
    }
  ],
  zoom: 6,
  initialized: true
};
