import React, { useRef } from 'react';
import AsyncSelect from 'react-select/async';
import SearchIcon from '../icons/search';
import './location-search.css';

declare const google;

export interface LocationSearchProps {
  style?: React.CSSProperties;
  placeholder?: string;
  onSearch?: (res: google.maps.places.PlaceResult | null) => void;
}

async function getSearch() {
  const {
    AutocompleteService,
    PlacesService
  } = (await google.maps.importLibrary('places')) as google.maps.PlacesLibrary;

  return {
    find: async (input: string) => {
      const a = new AutocompleteService();
      return await a.getPlacePredictions({
        input,
        componentRestrictions: {
          country: 'us'
        },
        types: ['locality', 'administrative_area_level_3']
      });
    },
    details: async (placeId: string) => {
      const ps = new PlacesService(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        document.getElementById('place-data')! as HTMLDivElement
      );
      return new Promise<google.maps.places.PlaceResult>((accept, reject) => {
        ps.getDetails(
          {
            placeId,
            fields: ['geometry.location', 'formatted_address']
          },
          (res, s) => {
            if (res) {
              accept(res);
            } else {
              reject(s);
            }
          }
        );
      });
    }
  };
}

// This has no internal state so has to be maintained externally
const LocationSearch: React.FC<LocationSearchProps> = ({
  style,
  placeholder = 'Search',
  onSearch = () => {
    /* NOOP */
  }
}) => {
  const selectRef = useRef<any>();
  const loadTimer = useRef<any>(null);
  const searchRef = useRef<{
    find: (input: string) => Promise<google.maps.places.AutocompleteResponse>;
    details: (placeId: string) => Promise<google.maps.places.PlaceResult>;
  } | null>(null);

  React.useEffect(() => {
    const setup = async () => {
      searchRef.current = await getSearch();
    };
    setup();
  });

  async function handleChange(
    value: google.maps.places.AutocompletePrediction
  ) {
    searchRef.current
      ?.details(value.place_id)
      .then(onSearch)
      .catch(err => console.warn('places error', err));
  }

  function handleSelectChange(res: any, options: { action: string }) {
    if (res && options.action === 'select-option') {
      handleChange(res.value);
      setTimeout(() => {
        selectRef.current.select.select.clearValue();
      }, 100);
    }
  }

  const loadOptions = (inputValue: string, callback: (res: any) => void) => {
    clearTimeout(loadTimer.current as any);
    if (inputValue.length > 3) {
      loadTimer.current = setTimeout(() => {
        searchRef.current
          ?.find(inputValue)
          .then(res => {
            callback(
              res.predictions.map(item => ({
                label: item.description.replace(', USA', ''),
                value: item
              }))
            );
          })
          .catch((err: any) => {
            console.error(err);
            callback([]);
          });
      }, 950);
    }
  };

  const options: any = {};
  if (typeof window !== 'undefined') {
    options.menuPortalTarget = document.body;
  }

  return (
    <div style={style} className="LocationSearch">
      <AsyncSelect
        ref={selectRef}
        onChange={handleSelectChange}
        placeholder={placeholder}
        className="LocationSearch-select"
        classNamePrefix="LocationSearch-select"
        cacheOptions
        loadOptions={loadOptions}
        {...options}
        isClearable={true}
        styles={{
          control: base => ({
            ...base,
            borderRadius: 0,
            height: '45px',
            borderColor: 'transparent',
            cursor: 'pointer'
          }),
          dropdownIndicator: () => ({ display: 'none' }),
          indicatorSeparator: () => ({ display: 'none' }),
          menuPortal: base => ({
            ...base,
            zIndex: 9999,
            marginTop: '-5px'
          }),
          menu: base => ({
            ...base,
            borderRadius: 0
          }),
          menuList: base => ({
            ...base,
            color: 'var(--pallet-black)'
          }),
          loadingIndicator: base => ({
            ...base,
            zIndex: 9999,
            background: 'white',
            padding: '10px'
          }),
          clearIndicator: base => ({
            ...base,
            zIndex: 9999,
            background: 'white',
            padding: '10px'
          }),
          valueContainer: base => ({
            ...base,
            padding: '0px 8px'
          }),
          input: base => ({
            ...base,
            fontWeight: 'var(--font-light)',
            fontSize: '16px',
            lineHeight: '45px',
            margin: 0,
            paddingTop: 0,
            paddingBottom: 0
          })
        }}
      />
      <div className="LocationSearch-search-icon">
        <SearchIcon />
      </div>
      <div style={{ display: 'none' }} id="place-data" />
    </div>
  );
};

export default LocationSearch;
