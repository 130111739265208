export type PromotionItem = {
  title: string;
  startDate: Date;
  endDate: Date;
  img: string;
  url?: string;
};

export const PROMOTIONS: Array<PromotionItem> = [
  {
    title: 'Holiday Gift Card',
    startDate: new Date('2023-11-01T00:00:00.000Z'),
    endDate: new Date('2024-01-02T00:00:00.000Z'),
    img: 'Fins-2022-Holiday-GIft-Card-Tile-1.jpeg'
  },
  {
    title: 'Fins Halloween BOGO',
    startDate: new Date('2023-10-27T00:00:00.000Z'),
    endDate: new Date('2023-11-01T00:00:00.000Z'),
    img: '23-AS-0806-Fins-Halloween-bogo-tile.jpeg'
  },
  {
    title: 'Fins Fest Greenville',
    startDate: new Date('2023-10-16T00:00:00.000Z'),
    endDate: new Date('2023-10-21T00:00:00.000Z'),
    img: '23-AS-0811-Fins-Fest-greenville-tile.jpeg',
    url: 'https://fb.me/e/7MNTxg3VN'
  },
  {
    title: 'Fins Cares &#8211; Washes for Wishes',
    startDate: new Date('2023-09-11T00:00:00.000Z'),
    endDate: new Date('2023-09-18T00:00:00.000Z'),
    img: '23-AS-0774-Fins-Washes-for-Wishes-tile.jpeg'
  },
  {
    title: 'Fins Fest Free Car Wash!',
    startDate: new Date('2023-09-05T00:00:00.000Z'),
    endDate: new Date('2023-09-09T00:00:00.000Z'),
    img: '23-AS-0783-Fins-Fest-Wilmington-Tile.jpeg'
  },
  {
    title: 'Fins Fest Holly Springs',
    startDate: new Date('2023-08-14T00:00:00.000Z'),
    endDate: new Date('2023-08-20T00:00:00.000Z'),
    img: '23-AS-0765-FINS-Fest-Holly-Springs-tile.jpeg'
  },
  {
    title: 'Fins Splash Sale End of Summer',
    startDate: new Date('2023-08-07T00:00:00.000Z'),
    endDate: new Date('2023-08-14T00:00:00.000Z'),
    img: '23-AS-0760-Fins-Splash-Sale-End-of-Summer-tile.jpeg'
  },
  {
    title: 'Happy Birthday Fins!',
    startDate: new Date('2023-07-10T00:00:00.000Z'),
    endDate: new Date('2023-07-13T00:00:00.000Z'),
    img: '23-AS-0739-Fins-4th-birthday-tile-420x420-1.jpeg'
  },
  {
    title: 'Father&#8217;s Day BOGO',
    startDate: new Date('2024-06-12T00:00:00.000Z'),
    endDate: new Date('2024-06-17T00:00:00.000Z'),
    img: '23-AS-0725-fins-fathers-day-tile.jpg'
  },
  {
    title: 'AAA Members Save at Fins',
    startDate: new Date('2023-06-01T00:00:00.000Z'),
    endDate: new Date('2024-06-01T00:00:00.000Z'),
    img: 'AAA-Member-FinsAcquisition.jpeg',
    url: 'https://join.carolinas.aaa.com/?promo=finslp1&level=basic'
  },
  {
    title: 'Happy Mother&#8217;s Day',
    startDate: new Date('2024-05-08T00:00:00.000Z'),
    endDate: new Date('2024-05-13T00:00:00.000Z'),
    img: '24-AS-0929-Fins-Mothers-Day-tile.jpg'
  },
  {
    title: '1325 Folly Rd Grand Opening',
    startDate: new Date('2023-04-20T00:00:00.000Z'),
    endDate: new Date('2023-05-01T00:00:00.000Z'),
    img: 'GO-Folly-Charleston-tile.jpeg'
  },
  {
    title: 'POLLEN season',
    startDate: new Date('2023-03-01T00:00:00.000Z'),
    endDate: new Date('2023-05-01T00:00:00.000Z'),
    img: '23-AS-0627-FINS-Pollen-Campaign-tile.jpeg'
  },
  {
    title: 'Valentine&#8217;s Day',
    startDate: new Date('2023-02-13T00:00:00.000Z'),
    endDate: new Date('2023-02-20T00:00:00.000Z'),
    img: '23-AS-0606-Fins-Cares-tile.jpeg'
  },
  {
    title: 'Splash Sale!',
    startDate: new Date('2023-02-01T00:00:00.000Z'),
    endDate: new Date('2023-02-06T00:00:00.000Z'),
    img: '23-AS-0602-Fins-Splash-Sale-tile.jpeg'
  },
  {
    title: 'Unlimited Washes',
    startDate: new Date('2023-01-01T00:00:00.000Z'),
    endDate: new Date('2023-01-31T00:00:00.000Z'),
    img: 'FWC_web-tile-promo-01.jpeg'
  },
  {
    title: 'Customer Appreciation Winston-Salem',
    startDate: new Date('2022-11-28T00:00:00.000Z'),
    endDate: new Date('2022-12-04T00:00:00.000Z'),
    img: 'Fins-Fest-WS-Tile.jpeg'
  },
  {
    title: 'Black Friday Buy One Get One',
    startDate: new Date('2022-11-21T00:00:00.000Z'),
    endDate: new Date('2022-11-27T00:00:00.000Z'),
    img: 'Fins-Black-Friday-2022-Tile.jpeg'
  },
  {
    title: 'The Perfect Holiday Gift',
    startDate: new Date('2022-11-01T00:00:00.000Z'),
    endDate: new Date('2023-01-01T00:00:00.000Z'),
    img: 'Fins-2022-Holiday-GIft-Card-Tile.jpeg'
  },
  {
    title: 'Spooky Clean Halloween',
    startDate: new Date('2022-10-25T00:00:00.000Z'),
    endDate: new Date('2022-11-01T00:00:00.000Z'),
    img: 'WEB-TILE-REFERENCE-Spooky-Clean-Halloween-Fins-IG.jpeg',
    url: 'https://fb.me/e/274V7mvMn'
  },
  {
    title: 'Fins Folly Rd Coming Soon',
    startDate: new Date('2022-09-23T00:00:00.000Z'),
    endDate: new Date('2022-10-31T00:00:00.000Z'),
    img: 'Coming-Soon-Folly-Charleston-Tile.jpeg'
  },
  {
    title: 'Splash Sale 5% OFF',
    startDate: new Date('2022-09-11T00:00:00.000Z'),
    endDate: new Date('2022-09-19T00:00:00.000Z'),
    img: 'Fins-Splash-Sale-22-tile.jpeg'
  },
  {
    title: 'Happy Birthday Fin!',
    startDate: new Date('2022-07-08T00:00:00.000Z'),
    endDate: new Date('2022-07-16T00:00:00.000Z'),
    img: 'Fins-3rd-birthday-tile-420x420-1.jpeg'
  },
  {
    title: 'Father&#8217;s Day',
    startDate: new Date('2022-06-15T00:00:00.000Z'),
    endDate: new Date('2022-06-20T00:00:00.000Z'),
    img: 'fins-bogo-fathers-day-tile-420x420-1.jpeg'
  },
  {
    title: 'Fins Fest',
    startDate: new Date('2022-06-07T00:00:00.000Z'),
    endDate: new Date('2022-06-11T00:00:00.000Z'),
    img: 'Fins-Fest-tile-1.jpeg',
    url: 'https://fb.me/e/5Fqpn3V2x'
  },
  {
    title: 'AAA Member Day',
    startDate: new Date('2022-05-24T00:00:00.000Z'),
    endDate: new Date('2023-05-31T00:00:00.000Z'),
    img: 'Member-days-tile-420x420-1.jpeg',
    url: 'https://facebook.com/finswash'
  },
  {
    title: 'Fins Mother&#8217;s Day',
    startDate: new Date('2022-05-05T00:00:00.000Z'),
    endDate: new Date('2022-05-09T00:00:00.000Z'),
    img: 'fins-bogo-mothers-day-tile-420x420-1.jpeg'
  },
  {
    title: 'Buy a Month, get a Month FREE',
    startDate: new Date('2022-03-01T00:00:00.000Z'),
    endDate: new Date('2022-04-30T00:00:00.000Z'),
    img: 'web-tile-pollen-offer.png'
  },
  {
    title: 'Holiday Gift Card',
    startDate: new Date('2021-11-16T00:00:00.000Z'),
    endDate: new Date('2021-12-31T00:00:00.000Z'),
    img: 'Fins-2021-Holiday-GIft-Card-tile-420x420-1.jpeg'
  },
  {
    title: 'We Are Here For You',
    startDate: new Date('2020-04-03T00:00:00.000Z'),
    endDate: new Date('2020-05-01T00:00:00.000Z'),
    img: 'FINS_Free_Washes_for_Med_Pros_Promo_Tile.jpeg',
    url: '/hereforyou'
  },
  {
    title: 'Pollen',
    startDate: new Date('2020-03-01T00:00:00.000Z'),
    endDate: new Date('2020-04-30T00:00:00.000Z'),
    img: 'suspended_Spring_BOGO_Wash_Club.jpeg',
    url: '/pollen'
  },
  {
    title: 'AAA Advantage',
    startDate: new Date('2020-02-05T00:00:00.000Z'),
    endDate: new Date('2020-12-31T00:00:00.000Z'),
    img: 'AAA_Promo_Tile_v2.jpeg',
    url: '/#Pricing'
  },
  {
    title: 'Fins Mobile App',
    startDate: new Date('2020-02-05T00:00:00.000Z'),
    endDate: new Date('2020-12-31T00:00:00.000Z'),
    img: 'Mobile_App_Promo_Tile.jpg',
    url: '/mobileapp'
  },
  {
    title: 'Give The Gift of Clean',
    startDate: new Date('2020-01-26T00:00:00.000Z'),
    endDate: new Date('2020-01-31T00:00:00.000Z'),
    img: 'gift-of-clean.jpeg'
  }
];
