export type LocationItem = {
  id: string;
  title: string;
  description: string;
  location: {
    lat: number;
    lng: number;
  };
  hours: string;
  available: 0 | 1 | 2; // 0: CLOSED, 1: OPEN, 2: COMING SOON
  phone: string; // only numbers
  cta: string;
};

export const LOCATIONS: Array<LocationItem> = [
  {
    id: '1',
    title: '1440 Woodruff Road, Greenville, SC 29607',
    description: '1440 Woodruff Road<br>Greenville, SC 29607',
    location: {
      lat: 34.8207,
      lng: -82.2827
    },
    hours: '8am-8pm',
    available: 1,
    phone: '8645641762',
    cta: 'ctaFins101'
  },
  {
    id: '2',
    title: '2906 Battleground Ave, Greensboro, NC 27408',
    description: '2906 Battleground Ave<br>Greensboro, NC 27408',
    location: {
      lat: 36.1128,
      lng: -79.8321
    },
    hours: '8am-8pm',
    available: 1,
    phone: '3367922940',
    cta: 'ctaFins104'
  },
  {
    id: '3',
    title: '7480 Rivers Ave, North Charleston, SC 29406',
    description: '7480 Rivers Ave<br>North Charleston, SC 29406',
    location: {
      lat: 32.9383,
      lng: -80.0392
    },
    hours: '8am-8pm',
    available: 1,
    phone: '8648499075',
    cta: 'ctaFins102'
  },
  {
    id: '4',
    title: '1109 Savannah Highway, Charleston, SC 29407',
    description: '1109 Savannah Highway<br>Charleston, SC 29407',
    location: {
      lat: 32.7833,
      lng: -79.994
    },
    hours: '8am-8pm',
    available: 1,
    phone: '8649005964',
    cta: 'ctaFins106'
  },
  {
    id: '5',
    title: '1638 New Garden Road, Greensboro, NC 27410',
    description: '1638 New Garden Road<br>Greensboro, NC 27410',
    location: {
      lat: 36.1169,
      lng: -79.8725
    },
    hours: '8am-8pm',
    available: 1,
    phone: '3367924092',
    cta: 'ctaFins108'
  },
  {
    id: '6',
    title: '106 Oak Ridge Ln, Wilmington, NC 28411',
    description: '106 Oak Ridge Ln<br>Wilmington, NC 28411',
    location: {
      lat: 34.2823,
      lng: -77.8109
    },
    hours: '8am-8pm',
    available: 1,
    phone: '9105487551',
    cta: 'ctaFins103'
  },
  {
    id: '7',
    title: '691 Long Point Rd Mt Pleasant, SC 29464',
    description: '691 Long Point Rd<br>Mt Pleasant, SC 29464',
    location: {
      lat: 32.8423,
      lng: -79.8547
    },
    hours: '8am-8pm',
    available: 1,
    phone: '8545290894',
    cta: 'ctaFins109'
  },
  {
    id: '8',
    title: '1027 N. Main Street Summerville, SC 29483',
    description: '1027 N. Main Street Summerville, SC 29483',
    location: {
      lat: 33.0315,
      lng: -80.1652
    },
    hours: '8am-8pm',
    available: 1,
    phone: '8439703396',
    cta: 'ctaFins107'
  },
  {
    id: '9',
    title: '3430 S. College Road Wilmington, NC 28412',
    description: '3430 S. College Road<br>Wilmington, NC 28412',
    location: {
      lat: 34.1822,
      lng: -77.8915
    },
    hours: '8am-8pm',
    available: 1,
    phone: '9105487560',
    cta: 'ctaFins105'
  },
  {
    id: '10',
    title: '2101 Laurens Road, Greenville, SC 29607',
    description: '2101 Laurens Road, <br>Greenville, SC 29607',
    location: {
      lat: 34.8366,
      lng: -82.3543
    },
    hours: '8am-8pm',
    available: 1,
    phone: '8648499066',
    cta: 'ctaFins110'
  },
  {
    id: '11',
    title: '911 S. College Road Wilmington, NC 28403',
    description: '911 S. College Road<br>Wilmington, NC 28403',
    location: {
      lat: 34.2179,
      lng: -77.8846
    },
    hours: '8am-8pm',
    available: 1,
    phone: '9102270081',
    cta: 'ctaFins111'
  },
  {
    id: '12',
    title: '3647 Ladson Rd, Ladson, SC 29456',
    description: '3647 Ladson Rd<br>Ladson, SC 29456',
    location: {
      lat: 32.9819,
      lng: -80.1248
    },
    hours: '8am-8pm',
    available: 1,
    phone: '8434384819',
    cta: ''
  },
  {
    id: '13',
    title: '1940 Healy Dr, Winston-Salem, NC 27103',
    description: '1940 Healy Dr<br>Winston-Salem, NC 27103',
    location: {
      lat: 36.0755,
      lng: -80.3018
    },
    hours: '8am-8pm',
    available: 1,
    phone: '3367909346',
    cta: ''
  },
  {
    id: '14',
    title: '100 Collins Meadow Dr, Holly Springs, NC 27540',
    description: '100 Collins Meadow Dr,<br>Holly Springs, NC 27540',
    location: {
      lat: 35.63337,
      lng: -78.82871
    },
    hours: '8am-8pm',
    available: 1,
    phone: '3367922924',
    cta: ''
  },
  {
    id: '15',
    title: '1325 Folly Rd, Charleston, SC 29412',
    description: '1325 Folly Rd, Charleston, SC 29412',
    location: {
      lat: 32.71529067441619,
      lng: -79.96570624619496
    },
    hours: '8am-8pm',
    available: 1,
    phone: '8542073422',
    cta: ''
  }
];
