import React, { useState } from 'react';
import BigText from '../../components/big-text';
import DotNav from '../../components/dot-nav';
import PriceCard from '../../components/price-card';
import HorizontalScroll from '../../components/horizontal-scroll';
import Button from '../../components/button';
import SectionSlideOver from '../../components/section-slide-over';
import TopWashClubIcon from '../../components/icons/top-wash-club';

import { NavItem } from '../../utils/types';
import { useMedia } from '../../utils/hooks';

import './pricing.css';

export interface PricingProps {
  number?: string;
  style?: React.CSSProperties;
  navItems?: Array<NavItem>;
  onNavItemSelected?: (index: number) => void;
  sale?: boolean;
  title?: boolean;
}

const Pricing: React.FC<PricingProps> = ({
  number,
  style,
  navItems,
  onNavItemSelected,
  sale = false,
  title = true
}) => {
  const [aaa, setAAA] = useState<boolean>(false);
  const [basicOpen, setBasicOpen] = useState<boolean>(false);
  const [standardOpen, setStandardOpen] = useState<boolean>(false);
  const [topWashOpen, setTopWashOpen] = useState<boolean>(false);
  const [topWashClubOpen, setTopWashClubOpen] = useState<boolean>(false);
  const scrollOptions = useMedia(
    [
      '(min-width:1650px)',
      '(min-width:1300px)',
      '(min-width:1095px)',
      '(min-width:760px)',
      '(min-width:0px)'
    ],
    [
      { slidesToShow: 5, variableWidth: false },
      { slidesToShow: 4 },
      { slidesToShow: 3 },
      { slidesToShow: 2 },
      { slidesToShow: 1 }
    ],
    {
      slidesToShow: 5,
      variableWidth: false
    }
  );
  const textOptions = useMedia(
    ['(max-width: 770px)', '(max-width: 414px)'],
    [
      {
        number: {
          left: '-20px'
        }
      },
      {
        number: {
          left: '-20px'
        }
      }
    ],
    {
      number: {
        left: '-100px'
      }
    }
  );
  const toggleAAA = () => setAAA(!aaa);

  function handleBasicClick() {
    setBasicOpen(!basicOpen);
  }

  function handleStandardClick() {
    setStandardOpen(!standardOpen);
  }

  function handleTopWashClick() {
    setTopWashOpen(!topWashOpen);
  }

  function handleTopWashClubClick() {
    setTopWashClubOpen(!topWashClubOpen);
  }

  return (
    <div id="Pricing" style={style}>
      <div className="Pricing-background-text">
        {number && (
          <BigText
            value={number}
            thin={true}
            style={{ position: 'absolute', top: '20px', ...textOptions.number }}
          />
        )}
      </div>
      <div className="Pricing-content">
        {title ? (
          <div className="Pricing-title">
            <div className="Pricing-title-content">
              <div className="Pricing-title-content-top">CHECK OUT THE</div>
              <div className="Pricing-title-content-main">
                Best Washes in Town
              </div>
              {number && (
                <DotNav
                  selected={parseInt(number, 10) - 1}
                  items={navItems}
                  dark={true}
                  onChange={onNavItemSelected}
                  style={{ marginTop: 'var(--spacing)' }}
                />
              )}
            </div>
          </div>
        ) : null}
        <div className="Pricing-details">
          <HorizontalScroll
            key={scrollOptions.slidesToShow}
            dark={true}
            infinite={true}
            dots={false}
            variableWidth={true}
            {...scrollOptions}
          >
            <div className="Pricing-item" style={{ paddingLeft: '1px' }}>
              <PriceCard
                style={{
                  marginRight: 'var(--spacing)',
                  marginLeft:
                    scrollOptions.slidesToShow === 4 ? 'var(--spacing-sm)' : 0
                }}
                title="AquaFIN Armor"
                color="var(--pallet-violet)"
                points={sale ? 0 : 15}
                price={sale ? 17 : 24}
                pricePerMonth={sale ? 34 : 44}
                aaaDiscount={3}
                aaaDiscountPerMonth={5}
                aaa={aaa}
                extras={{
                  'Includes:':
                    'Graphene - NEW!, Ceramic Protectant, 2-Stage Wheel Cleaner, Triple Foam, Tire Shine, Underbody, High Pressure',
                  'Plus:':
                    'Free vacuums, mat cleaners, glass cleaner and towels at all locations'
                }}
              />
            </div>
            <div className="Pricing-item" style={{ paddingLeft: '1px' }}>
              <PriceCard
                style={{
                  marginRight: 'var(--spacing)',
                  marginLeft:
                    scrollOptions.slidesToShow === 4 ? 'var(--spacing-sm)' : 0
                }}
                title="Protect"
                color="var(--pallet-orange)"
                points={sale ? 0 : 15}
                price={sale ? 5 : 21}
                pricePerMonth={sale ? 0 : 39}
                aaaDiscount={2}
                aaaDiscountPerMonth={5}
                aaa={aaa}
                extras={{
                  'Includes:':
                    'Ceramic Protectant, Carnauba Wax, Sea Foam, 2-Stage Wheel Cleaner, Triple Foam, Tire Shine, High Pressure',
                  'Plus:':
                    'Free vacuums, mat cleaners, glass cleaner and towels at all locations'
                }}
              />
            </div>
            <div className="Pricing-item">
              <PriceCard
                style={{
                  marginRight: 'var(--spacing-sm)'
                }}
                title="Essential"
                color="var(--pallet-red)"
                points={sale ? 0 : 20}
                price={sale ? 10 : 14}
                pricePerMonth={sale ? 20 : 29}
                aaaDiscount={1}
                aaaDiscountPerMonth={3}
                aaa={aaa}
                extras={{
                  'Includes:':
                    'Tire & Wheel Cleaner, Triple Foam, Tire Shine, Underbody, +$3 Carnauba Wax, + $3 Ceramic Protectant',
                  'Plus:':
                    'Free vacuums, mat cleaners, glass cleaner and towels at all locations'
                }}
              />
            </div>
            <div className="Pricing-item">
              <PriceCard
                style={{
                  marginRight: 'var(--spacing-sm)'
                }}
                title="Express"
                color="var(--pallet-blue)"
                points={sale ? 0 : 25}
                price={sale ? 0 : 7}
                pricePerMonth={sale ? 30 : 19}
                aaaDiscount={0}
                aaaDiscountPerMonth={1}
                aaa={aaa}
                extras={{
                  'Add on:':
                    '+$3 Tire & Wheel Cleaner, + $5 Tire & Wheel Cleaner with Tire Shine',
                  'Plus:':
                    'Free vacuums, mat cleaners, glass cleaner and towels at all location'
                }}
              />
            </div>
            <div className="Pricing-item">
              <PriceCard
                style={{
                  marginRight: 'var(--spacing-sm)'
                }}
                title="Fins Wash Club"
                color="var(--pallet-green-wash-club)"
                points={0}
                priceRanges
                price={sale ? 20 : 19}
                priceMax={sale ? 30 : 44}
                aaaDiscount={5}
                aaa={aaa}
                icon={<TopWashClubIcon secondaryColor="currentColor" />}
                extras={{
                  '':
                    'Join Fins Wash Club for unlimited washes at an unbeatable value. We offer one low monthly price with convenient automatic billing. You may purchase and use your plan at any Fins Car Wash location.'
                }}
              />
            </div>
            {scrollOptions.slidesToShow < 4 && (
              <div className="Pricing-item"></div>
            )}
          </HorizontalScroll>
        </div>

        <h2
          style={{
            margin: 0,
            textAlign: 'center',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '18px',
            letterSpacing: 0,
            marginTop: 'var(--spacing-lg)',
            marginBottom: 'var(--spacing)',
            display: 'none'
          }}
        >
          Fins loyalty customers earn 10 loyalty points with each wash, and for
          every 90 points earned, you’ll get a FREE wash!
        </h2>

        <div className="Pricing-action">
          <Button
            text={aaa ? 'VIEW STANDARD PRICING' : 'VIEW AAA MEMBER PRICING'}
            onClick={toggleAAA}
          />
        </div>
      </div>
      <SectionSlideOver
        open={basicOpen}
        onClose={handleBasicClick}
        style={{ color: 'var(--pallet-black)', padding: 'var(--spacing-lg)' }}
      >
        Some content about basic wash
      </SectionSlideOver>
      <SectionSlideOver
        open={standardOpen}
        onClose={handleStandardClick}
        style={{ color: 'var(--pallet-black)', padding: 'var(--spacing-lg)' }}
      >
        Some content about standard wash
      </SectionSlideOver>
      <SectionSlideOver
        open={topWashOpen}
        onClose={handleTopWashClick}
        style={{ color: 'var(--pallet-black)', padding: 'var(--spacing-lg)' }}
      >
        Some content about Top Wash wash
      </SectionSlideOver>
      <SectionSlideOver
        open={topWashClubOpen}
        onClose={handleTopWashClubClick}
        style={{ color: 'var(--pallet-black)', padding: 'var(--spacing-lg)' }}
      >
        Some content about Top Wash Club wash
      </SectionSlideOver>
    </div>
  );
};

export default Pricing;
