import React, { useState, useEffect } from 'react';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';

import Layout from '../components/layout';
import SEO from '../components/seo';

import AboutUs from '../sections/about-us';
import Promotions from '../sections/promotions';
import OurStore from '../sections/our-store';
import FinsAAA from '../sections/fins-aaa';
import Pricing from '../sections/pricing';
import TopWashClub from '../sections/top-wash-club';
import Careers from '../sections/careers';
import Location from '../sections/location';
// import Loyalty from '../sections/loyalty';
import Sustainability from '../sections/sustainability';

import items, { otherLinks } from '../utils/nav-items';

const client = new ApolloClient({
  uri: 'https://finsautowash.com/graphql'
});
const IndexPage: React.FC<any> = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>(0);
  useEffect(() => setLoaded(true), []);
  const onNavItemSelected = (index: number) => {
    setSelected(index);
  };
  return (
    <ApolloProvider client={client}>
      {/* <EmergencyBanner message="FINS is taking preventative measures to limit the spread of COVID-19 and our operating locations are currently open." /> */}
      <Layout
        key={`${loaded}-${typeof window}`}
        navItems={items}
        hashLinkItems={otherLinks}
        onNavItemSelected={onNavItemSelected}
        selected={selected}
      >
        <SEO
          title="Fins Car Wash"
          description="Unlock savings at Fins Car Wash by becoming a member."
        />
        <AboutUs
          number="01"
          style={{ paddingTop: 'var(--header-height)' }}
          navItems={items}
          onNavItemSelected={onNavItemSelected}
        />
        {/* <PromotionUpdate message="The purchase of new Fins Wash Club memberships and Gift Cards are temporarily suspended to reduce direct person-to-person contact." /> */}
        <Promotions
          number="02"
          navItems={items}
          onNavItemSelected={onNavItemSelected}
        />
        {/* <OurStore number="03" />
        <FinsAAA
          number="04"
          navItems={items}
          onNavItemSelected={onNavItemSelected}
        /> */}
        <Pricing
          number="05"
          navItems={items}
          onNavItemSelected={onNavItemSelected}
        />
        <TopWashClub
          number="06"
          navItems={items}
          onNavItemSelected={onNavItemSelected}
        />
        <Sustainability
          number="07"
          navItems={items}
          onNavItemSelected={onNavItemSelected}
        />
        <Location
          loaded={loaded}
          number="08"
          navItems={items}
          onNavItemSelected={onNavItemSelected}
        />
        <Careers
          number="09"
          navItems={items}
          onNavItemSelected={onNavItemSelected}
        />
        {/* <Loyalty
          number="10"
          navItems={items}
          onNavItemSelected={onNavItemSelected}
        /> */}
      </Layout>
    </ApolloProvider>
  );
};

export default IndexPage;
