import React, { memo } from 'react';
import classnames from 'classnames';

export interface DotNavDotProps {
  index: number;
  selected?: boolean;
  dark?: boolean;
  title?: string;
  onClick?: (index: number) => void;
}

const DotNavDot: React.FC<DotNavDotProps> = ({
  index,
  selected,
  dark,
  title,
  onClick
}) => {
  const className = classnames({
    DotNavDot: true,
    DotNavDot__dark: dark, // eslint-disable-line @typescript-eslint/camelcase
    DotNavDot__selected: selected // eslint-disable-line @typescript-eslint/camelcase
  });
  const handleClick = () => {
    if (onClick) {
      onClick(index);
    }
  };
  return (
    <div
      className={className}
      role="button"
      onClick={handleClick}
      title={title}
    />
  );
};

export default memo(DotNavDot);
