import React from 'react';
import { IconProps } from './types';

const FinsWashSmallIcon: React.FC<IconProps> = ({
  color = '#fff',
  secondaryColor = '#94cc00',
  height = 49.61,
  width = 40.33,
  style = {}
}) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="img"
      style={{ width, height, ...style }}
      viewBox="0 0 40.331 49.609"
    >
      <path
        d="M226.3,73.888l-1.085-1.851c-.093-.173-.2-.358-.323-.553l0,0c-.875-1.4-2.333-3.235-2.531-3.522-.127-.185-1.478-2.058-1.584-2.2-5.785-7.628-12.04-12.074-21.421-15.629-.786-.3-1.51.633-1.228,1.585a78.865,78.865,0,0,1,2.28,9.171,89.1,89.1,0,0,1,.534,11.13l-.124,2.148A.906.906,0,0,0,201.6,75.2H225.75C226.3,75.2,226.618,74.438,226.3,73.888Zm-3.546-.462h-2.83a.287.287,0,0,1-.259-.208,39.581,39.581,0,0,0-11.735-16.34c-.074-.06-.01-.2.069-.152A43.208,43.208,0,0,1,222.983,72.88C223.111,73.112,222.978,73.426,222.75,73.426Z"
        transform="scale(0.78) translate(-184 -27)"
        fill={color}
      />
      <path
        d="M203.7,27.7a3.443,3.443,0,1,0-5.746-2.657,20.168,20.168,0,0,0-22.731,29.868,3.594,3.594,0,0,0,4.674,5.3A20.164,20.164,0,1,0,203.7,27.7ZM192.465,61.73a17.2,17.2,0,0,1-10.882-3.868,3.6,3.6,0,0,0-3.522-4.34c-.1,0-.193.007-.288.015a17.28,17.28,0,1,1,14.692,8.193Z"
        transform="translate(-172.3 -15.757)"
        fill={secondaryColor}
      />
      <circle
        cx="2.184"
        cy="2.184"
        r="2.184"
        transform="translate(23.167)"
        fill={secondaryColor}
      />
      <path
        d="M250.986,109.787A2.016,2.016,0,1,0,253,111.8,2.016,2.016,0,0,0,250.986,109.787Z"
        transform="translate(-214.472 -64.209)"
        fill={secondaryColor}
      />
    </svg>
  );
};

export default FinsWashSmallIcon;
