import React from 'react';
import { IconProps } from './types';

const spacing = 4.5;
const dotSize = 12;
const rad = dotSize / 2;
const rows = 3;
const cols = 3;
const scaleWidth = dotSize * rows + spacing * (cols - 1);
const scaleHeight = dotSize * rows + spacing * (rows - 1);

const Row: React.FC<{ color: string; transform: string }> = ({
  color,
  transform
}) => {
  const columns: any = [];
  for (let i = 0; i < cols; i += 1) {
    columns.push(
      <circle
        key={dotSize * i + spacing * i + rad}
        fill={color}
        r={rad}
        cy={rad}
        cx={dotSize * i + spacing * i + rad}
      ></circle>
    );
  }

  return <g transform={transform}>{columns}</g>;
};

const MenuIcon: React.FC<IconProps> = ({
  color = '#fff',
  height = scaleHeight,
  width = scaleWidth,
  style
}) => {
  const rowList: any = [];
  for (let i = 0; i < rows; i += 1) {
    rowList.push(
      <Row
        key={dotSize * i + spacing * i}
        color={color}
        transform={`translate(0 ${dotSize * i + spacing * i})`}
      />
    );
  }
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="img"
      style={style}
      width={width}
      height={height}
      viewBox={`0 0 ${scaleWidth} ${scaleHeight}`}
    >
      {rowList}
    </svg>
  );
};

export default MenuIcon;
