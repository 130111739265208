import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import animateScrollTo from 'animated-scroll-to';
import { NavItem } from '../../utils/types';
import { useMedia } from '../../utils/hooks';
import LogoSmall from '../icons/finswash-small';
import MenuIcon from '../icons/menu';
import './header.css';
import HorizontalScroll from '../horizontal-scroll';
import CloseIcon from '../icons/close';
import Button from '../button';

export interface HeaderProps {
  container?: React.RefObject<HTMLElement>;
  items: Array<NavItem>;
  otherItems: Array<NavItem>;
  scrollTopThreshold?: number;
  scrollSelectThreshold?: number;
  selected?: number;
  onNavItemSelected?: (index: number) => void;
}

const Header: React.FC<HeaderProps> = ({
  items,
  otherItems = [],
  container,
  scrollTopThreshold = 40,
  scrollSelectThreshold = 15,
  selected: selectedBase = 0,
  onNavItemSelected
}) => {
  const y =
    container && container.current
      ? container.current.scrollTop
      : typeof window !== 'undefined'
      ? window.scrollY
      : 0;
  const [loaded, setLoaded] = useState<boolean>(false);
  const [atTop, setAtTop] = useState<boolean>(y <= scrollTopThreshold);
  const [selected, setSelected] = useState<number>(selectedBase + 1);
  const [open, setOpen] = useState<boolean>(false);
  const allowFull = useMedia(
    ['(min-width: 1200px)', '(min-width: 0px)'],
    [true, false],
    true
  );
  const size = useMedia(
    ['(max-width: 670px)', '(max-width: 1024px)'],
    [
      { mobile: true, tablet: false, desktop: false },
      { tablet: true, desktop: false, mobile: false }
    ],
    { mobile: false, tablet: false, desktop: true }
  );
  const slider = useRef<any>(null);

  const handleScroll = () => {
    const y =
      container && container.current
        ? container.current.scrollTop
        : typeof window !== 'undefined'
        ? window.scrollY
        : 0;
    if (!atTop && y <= scrollTopThreshold) {
      setAtTop(true);
    } else if (atTop && y > scrollTopThreshold) {
      setAtTop(false);
    }
    let sel;
    for (let i = 0; i < items.length; i += 1) {
      const elm = document.getElementById(items[i].id);
      if (
        elm &&
        Math.abs((elm.getBoundingClientRect() as any).y) <=
          scrollSelectThreshold
      ) {
        sel = i + 1;
        break;
      }
    }
    if (sel) {
      setSelected(sel);
      setOpen(false);
    }
  };

  useEffect(() => setLoaded(true), []);

  useEffect(() => {
    const allItems = [...items, ...otherItems];
    if (allItems[selectedBase]) {
      const box = container && container.current ? container.current : window;
      const elm = document.getElementById(allItems[selectedBase].id);
      if (elm) {
        animateScrollTo(elm, { elementToScroll: box });
      }
    }
  }, [selectedBase]);

  useEffect(() => {
    const box = container && container.current ? container.current : document;
    box.addEventListener('scroll', handleScroll);
    return () => box.removeEventListener('scroll', handleScroll);
  }, [container, atTop]);

  useEffect(() => {
    if (slider.current) {
      slider.current.slickGoTo(selected);
    }
  }, [open, selected]);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const box = container && container.current ? container.current : window;
    const href = e.currentTarget.getAttribute('href');
    if (href) {
      if (onNavItemSelected) {
        onNavItemSelected(items.map(i => i.id).indexOf(href.replace(/#/g, '')));
      } else {
        const elm = document.getElementById(href.replace(/#/g, ''));
        if (elm) {
          animateScrollTo(elm, { elementToScroll: box });
          setOpen(false);
        }
      }
    }
  };

  const openMenu = () => {
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const displayHeaderLinks = items.map((item, idx) => (
    <a
      className={classnames({
        'Header-item': true,
        'Header-item__selected': idx === selected - 1
      })}
      key={item.label}
      href={item.id == 'ManageMembership' ? '/manage-membership' : `#${item.id}`}
      onClick={handleClick}
    >
      {item.label}
    </a>
  ));

  return (
    <header
      key={`${loaded}-${typeof window}-${allowFull}-${atTop}`}
      className={classnames({
        Header: true,
        'Header__at-top': allowFull && atTop,
        'Header__is-open': open
      })}
    >
      {allowFull && atTop && (
        <div className="Header-items">{displayHeaderLinks}</div>
      )}
      {!(allowFull && atTop) && (
        <>
          <LogoSmall
            style={{
              marginRight: !open ? 'var(--spacing)' : 'var(--spacing-lg)'
            }}
            height={
              !open
                ? 'var(--header-icon-compressed)'
                : 'var(--header-height-compressed)'
            }
            width={'auto'}
          />
          {!open && (
            <div
              role="button"
              onClick={openMenu}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              className="cursor-pointer"
            >
              <MenuIcon
                style={{
                  height: 'var(--header-icon-compressed)',
                  width: 'var(--header-icon-compressed'
                }}
              />
            </div>
          )}
          {size.desktop && (
            <div className="Header-items__scroll">
              <HorizontalScroll
                ref={slider}
                variableWidth={true}
                infinite={false}
              >
                {displayHeaderLinks}
              </HorizontalScroll>
            </div>
          )}
          {open && size.tablet && (
            <div className="Header-items__full-screen">
              <div className="Header-items__header">
                <LogoSmall height={'40px'} />
                <Button className="inline" onClick={closeMenu}>
                  <CloseIcon />
                </Button>
              </div>
              <div className="Header-items__tablet">{displayHeaderLinks}</div>
            </div>
          )}
          {open && size.mobile && (
            <div className="Header-items__full-screen">
              <div className="Header-items__header">
                <LogoSmall height={'40px'} />
                <Button className="inline" onClick={closeMenu}>
                  <CloseIcon />
                </Button>
              </div>
              <div className="Header-items__mobile">{displayHeaderLinks}</div>
            </div>
          )}
        </>
      )}
    </header>
  );
};

export default Header;
