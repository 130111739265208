/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react';
// import { useStaticQuery, graphql } from 'gatsby';

import Header from '../header';
import Footer from '../footer';
import { NavItem } from '../../utils/types';
import './layout.css';

export interface LayoutProps {
  navItems: Array<NavItem>;
  hashLinkItems?: Array<NavItem>;
  children: React.ReactNode;
  selected?: number;
  onNavItemSelected: (index: number) => void;
  handleSelect?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  navItems,
  hashLinkItems = [],
  onNavItemSelected,
  selected,
  handleSelect = false
}) => {
  useEffect(() => {
    const allItems = [...navItems, ...hashLinkItems];
    setTimeout(() => {
      if (typeof window !== 'undefined' && window.location.hash) {
        const idx = allItems
          .map(i => i.id)
          .indexOf(window.location.hash.slice(1));
        if (idx > -1) {
          history.replaceState(
            history.state,
            'FinsWash',
            `${window.location.origin}${window.location.pathname}`
          );
          onNavItemSelected(idx);
        }
      }
    }, 230);
  }, []);
  return (
    <>
      <Header
        items={navItems}
        otherItems={hashLinkItems}
        selected={selected}
        onNavItemSelected={handleSelect ? onNavItemSelected : undefined}
      />
      <div>{children}</div>
      <Footer
        items={navItems}
        selected={selected}
        onNavItemSelected={onNavItemSelected}
      />
    </>
  );
};

export default Layout;
