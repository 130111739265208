import React, { useEffect } from 'react';
import classnames from 'classnames';
import DotNavDot from './dot-nav-dot';
import { NavItem } from '../../utils/types';
import './dot-nav.css';

export interface DotNavProps {
  items?: Array<NavItem>;
  dark?: boolean;
  vertical?: boolean;
  selected?: number;
  onChange?: (selected: number) => void;
  autoChangeInterval?: number;
  style?: React.CSSProperties;
}

// This has no internal state so has to be maintained externally
const DotNav: React.FC<DotNavProps> = ({
  items,
  dark,
  style,
  vertical,
  selected,
  onChange,
  autoChangeInterval
}) => {
  const count = items?.length;
  const handleDotClick = (index: number) => {
    if (onChange) {
      onChange(index);
    }
  };

  // handle auto select interval
  useEffect(() => {
    let intervalKey;
    if (autoChangeInterval) {
      intervalKey = setInterval(() => {
        if (selected === count - 1) {
          handleDotClick(0);
        } else {
          handleDotClick(count + 1);
        }
      }, autoChangeInterval);
    }
    return () => clearInterval(intervalKey);
  }, []);

  // Build dot children list
  const children: Array<any> = [];
  for (let i = 0; i < count; i += 1) {
    children.push(
      <DotNavDot
        key={i}
        index={i}
        title={items[i].label}
        selected={selected === i}
        dark={dark}
        onClick={handleDotClick}
      />
    );
  }

  // style class names
  const className = classnames({
    DotNav: true,
    DotNav__vertical: vertical // eslint-disable-line @typescript-eslint/camelcase
  });

  return (
    <div style={style} className={className}>
      {children}
    </div>
  );
};

export default DotNav;
