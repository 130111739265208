import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import DotNav from '../../components/dot-nav';
import BigText from '../../components/big-text';
import Button from '../../components/button';
import { NavItem } from '../../utils/types';
import VideoButton from '../../components/video-button';
import './careers.css';

export interface CareersProps {
  number: string;
  style?: React.CSSProperties;
  navItems: Array<NavItem>;
  onNavItemSelected: (index: number) => void;
}

const Careers: React.FC<CareersProps> = ({
  number,
  style,
  navItems,
  onNavItemSelected
}) => {
  const cutoutImage = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "cutout-lady.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div id="Careers" style={style}>
      <div className="Careers-background-text">
        <BigText
          value={number}
          style={{ position: 'absolute', left: '-100px', top: 20 }}
        />
      </div>
      <div className="Careers-content">
        <div className="Careers-inner-content">
          <div className="Careers-title">
            <DotNav
              selected={parseInt(number, 10) - 1}
              items={navItems}
              vertical={true}
              dark={true}
              onChange={onNavItemSelected}
              style={{ marginLeft: '-30px', position: 'absolute' }}
            />
            <div className="Careers-title-content">
              <div className="Careers-title-content-top">ADVANCE YOUR</div>
              <div className="Careers-title-content-main">Career</div>
            </div>
          </div>
          <div className="Careers-details">
            <p>
              At Fins Car Wash, we want team members who have a passion for
              people. Whether it&apos;s your first job, a career change or a
              part time role, we offer our team members the opportunity to help
              others.
            </p>
            <p>We are FAST, FUN, and FINTASTIC!</p>
          </div>
          <div className="Careers-actions">
            <a
              href="https://finswash.com/careers/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button text="LEARN MORE" className="ctaLMJobs" />
            </a>
            <a href="https://whistleexpresscarwash.com/careers/">
              <Button
                className="white ctaFJobs"
                text="SEARCH JOBS"
                style={{ marginLeft: '16px' }}
              />
            </a>
          </div>
        </div>
        <div className="Careers-content-image-container">
          <div className="circles-1">
            <div className="circles-2">
              <div className="circles-3">
                <div className="circles-4"></div>
              </div>
            </div>
          </div>
          <Img
            fluid={cutoutImage.placeholderImage.childImageSharp.fluid}
            className="Careers-content-image"
          />
        </div>
      </div>
      <VideoButton
        style={{ position: 'absolute', zIndex: 10, bottom: 0, right: 0 }}
        videoId="98ASJqAEbjM"
        text="Watch Video"
      />
    </div>
  );
};

export default Careers;
