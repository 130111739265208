import React, { useState } from 'react';
import './card.css';

export interface CardProps {
  topperColor?: string;
  topperSize?: 'small' | 'large' | 'none';
  elevation?: 0 | 1 | 2 | 3 | 4;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: VoidFunction;
  hoverIncreaseElevation?: number;
}

const Card: React.FC<CardProps> = ({
  topperColor = 'var(--pallet-white)',
  topperSize = 'none',
  elevation = 0,
  children,
  hoverIncreaseElevation = 0,
  style: propStyle = {},
  onClick
}) => {
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const style = {
    ...propStyle,
    '--card-topper-color': topperColor
  } as React.CSSProperties;
  return (
    <div
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      style={style}
      className={`Card Card__elevation-${elevation +
        (mouseOver ? hoverIncreaseElevation : 0)} Card__topper-${topperSize}`}
      role={onClick ? 'button' : undefined}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Card;
