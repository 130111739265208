import React, { useEffect, useRef, useState } from 'react';
import LazyLoad from 'react-lazyload';
import classnames from 'classnames';

import { NavItem } from '../../utils/types';
import { useMedia } from '../../utils/hooks';
import animateScrollTo from 'animated-scroll-to';
import BigText from '../../components/big-text';
import DotNav from '../../components/dot-nav';
import LocationSearch from '../../components/location-search';
import { MAP_OPTIONS } from './map-options';
import { LOCATIONS, LocationItem } from './locations';
import { checkPhoneNumber } from './phone';

import './location.css';
import CloseIcon from '../../components/icons/close';

declare const google;

export interface LocationProps {
  loaded?: boolean;
  number?: string;
  style?: React.CSSProperties;
  navItems?: Array<NavItem>;
  onNavItemSelected?: (index: number) => void;
}

const ListItem: React.FC<{
  item: LocationItem;
  onClick: (item: any) => void;
  selectedId: string;
  distance?: string;
}> = ({ item, onClick, selectedId, distance }) => {
  function handleItemClick() {
    onClick(item);
  }

  return (
    <div
      id={`list-time-${item.id}`}
      className={classnames({
        'Location-map-list-item': true,
        'Location-map-list-item__is-selected': item.id === selectedId,
        [item.cta]: !!item.cta
      })}
      onClick={handleItemClick}
    >
      <div className="Location-map-list-item--title">{item.title}</div>
      <div className="Location-map-list-item--hours">
        {/* {item.hours} */}
        <a
          href={`tel:${checkPhoneNumber(item.phone)}`}
        >
          {checkPhoneNumber(item.phone)}
        </a>
        {distance && (
          <span
            style={{ marginLeft: 10 }}
            className="Location-map-list-item--distance"
          >
            {`${parseFloat(distance).toFixed(2)} Miles`}
          </span>
        )}
        <a
          style={{ marginLeft: 10 }}
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.google.com/maps/dir//${item.location.lat},${item.location.lng}`}
        >
          Directions
        </a>
      </div>
    </div>
  );
};

function setMarkers(
  map: google.maps.Map,
  onMarkerClick: (item: LocationItem) => void
) {
  for (const item of LOCATIONS) {
    const marker = new google.maps.Marker({
      position: item.location,
      map,
      title: item.title
    });
    marker.addListener('click', () => {
      onMarkerClick(item);
    });
  }
}

const LocationCore: React.FC<LocationProps> = ({
  loaded,
  number,
  navItems,
  onNavItemSelected
}) => {
  const map = useRef<google.maps.Map | null>(null);
  const distanceService = useRef<google.maps.DistanceMatrixService | null>(
    null
  );
  const listContainer = useRef<any>();
  const [listOpen, setListOpen] = useState<any>(true);
  const [
    searchRes,
    setSearchRes
  ] = useState<google.maps.places.PlaceResult | null>(null);
  const [selectedMarkerId, setSelectedMarkerId] = useState('');
  const [filter, setFilter] = useState<Record<string, string> | null>(null);
  const verticalBar = useMedia(['(max-width:440px)'], [false], true);

  function handleMarkerClick(marker: any, scroll = false) {
    setSelectedMarkerId(marker.id);
    map.current?.setCenter(marker.location);
    map.current?.setZoom(10);
    if (scroll) {
      const elm = document.getElementById(`list-time-${marker.id}`);
      if (elm) animateScrollTo(elm, { elementToScroll: listContainer.current });
    }
  }

  async function initMap() {
    const { Map } = (await google.maps.importLibrary(
      'maps'
    )) as google.maps.MapsLibrary;
    const { DistanceMatrixService } = (await google.maps.importLibrary(
      'routes'
    )) as google.maps.RoutesLibrary;
    map.current = new Map(
      document.getElementById('map-container') as HTMLElement,
      MAP_OPTIONS
    );

    distanceService.current = new DistanceMatrixService();

    setMarkers(map.current, handleMarkerClick);
  }

  useEffect(() => {
    if (loaded) initMap();
  }, [loaded]);

  async function handleSearch(res: google.maps.places.PlaceResult | null) {
    if (res === null) {
      setSearchRes(null);
      setTimeout(() => {
        map.current?.setCenter(MAP_OPTIONS.center);
        map.current?.setZoom(MAP_OPTIONS.zoom);
      }, 250);
    } else {
      setSearchRes(res);
      setTimeout(() => {
        if (res.geometry?.location) {
          map.current?.setCenter({
            lat: res.geometry.location.lat(),
            lng: res.geometry.location.lng()
          });
          distanceService.current
            ?.getDistanceMatrix({
              origins: [res.geometry.location],
              destinations: LOCATIONS.map(i => i.location),
              travelMode: 'DRIVING' as google.maps.TravelMode,
              unitSystem: 1.0 as google.maps.UnitSystem
            })
            .then(res => {
              setFilter(
                res.rows[0].elements.reduce((res, entry, idx) => {
                  // 40 km
                  if (entry.distance.value <= 40000) {
                    res[LOCATIONS[idx].id] = entry.distance.text;
                  }
                  return res;
                }, {} as Record<string, string>)
              );
            })
            .catch(err => {
              console.warn('distance api err', err);
            });
        }
        map.current?.setZoom(9);
      }, 250);
    }
  }

  const filterKeys = filter ? Object.keys(filter) : null;
  const [nowOpenList, comingSoonList] = LOCATIONS.reduce(
    (res, item) => {
      if (item.available === 1 || item.available === 2) {
        if (!filterKeys || filterKeys.includes(item.id)) {
          const idx = item.available - 1;
          res[idx].push(
            <ListItem
              key={item.id}
              item={item}
              distance={filter?.[item.id]}
              onClick={handleMarkerClick}
              selectedId={selectedMarkerId}
            />
          );
        }
      }
      return res;
    },
    [[], []] as [React.ReactElement[], React.ReactElement[]]
  );

  function toggleList() {
    setListOpen(old => !old);
  }

  function clearList() {
    setListOpen(false);
  }

  function clearSearch() {
    setSearchRes(null);
    setFilter(null);
  }

  return (
    <>
      <div className="Location-background-text">
        <BigText
          value={number ?? ''}
          thin={true}
          style={{ position: 'absolute', right: '-20px', top: '50px' }}
        />
      </div>
      <div className="Location-content">
        <div className="Location-title">
          <div className="Location-title-content">
            <div className="Location-title-content-top">FIND YOUR NEAREST</div>
            <div className="Location-title-content-main">Location</div>
            {!verticalBar && typeof number === 'number' && (
              <DotNav
                selected={Number(number) - 1}
                items={navItems}
                vertical={false}
                dark={true}
                onChange={onNavItemSelected}
              />
            )}
          </div>
        </div>
        <div className="Location-map-container">
          {verticalBar && typeof number === 'number' && (
            <DotNav
              selected={Number(number) - 1}
              items={navItems}
              vertical={true}
              dark={true}
              onChange={onNavItemSelected}
              style={{ position: 'absolute', right: -30 }}
            />
          )}
          <div className="Location-map-search">
            <LocationSearch
              placeholder="Search by City"
              onSearch={handleSearch}
            />
          </div>
          <div className="Location-map-search-info">
            <div className="Location-map-search-info-last">
              <div
                className={`Location-map-search-info-last-title${searchRes ? ' clickable' : ''
                  }`}
                onClick={searchRes ? clearSearch : undefined}
              >
                {searchRes ? (
                  <>
                    <div title="Clear" style={{ marginRight: 5 }}>
                      <CloseIcon height={16} width={8} />
                    </div>
                    YOU SEARCHED
                  </>
                ) : (
                  <>&nbsp;</>
                )}
              </div>
              <div className="Location-map-search-info-last-result">
                {searchRes?.formatted_address ? (
                  searchRes.formatted_address.replace(', USA', '')
                ) : (
                  <>&nbsp;</>
                )}
              </div>
            </div>
            <div className="Location-map-search-info-list">
              <div className="Location-map__contact-us" style={{ display: 'none ' }}>
                CONTACT US: <a href="tel:833-606-3467">833-606-FINS</a>
              </div>
              <div
                role="button"
                className="Location-map-search-info-list-item item-first"
                style={!listOpen ? { fontWeight: 700 } : { color: '#ccc' }}
                onClick={clearList}
              >
                MAP
              </div>
              <div
                role="button"
                style={listOpen ? { fontWeight: 700 } : { color: '#ccc' }}
                onClick={toggleList}
                className="Location-map-search-info-list-item"
              >
                LIST
              </div>
            </div>
          </div>
          <div id="map-container"></div>
          {/* <div
            className="Location-map-search-here"
            role="button"
            onClick={handleSearchHere}
          >
            Search Here
          </div> */}
          {listOpen && (
            <div className="Location-map-list" ref={listContainer}>
              {nowOpenList.length > 0 && (
                <div className="Location-map-list--section-title">NOW OPEN 8AM - 8PM</div>
              )}
              {nowOpenList}
              {nowOpenList.length > 0 && comingSoonList.length > 0 && (
                <div className="Location-map-list--section-rule"></div>
              )}
              {comingSoonList.length > 0 && (
                <div className="Location-map-list--section-title">
                  COMING SOON
                </div>
              )}
              {comingSoonList}
              {nowOpenList.length === 0 && comingSoonList.length === 0 && (
                <div className="Location-map-list--empty">
                  No Locations Nearby
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const Location: React.FC<LocationProps> = ({ style, ...props }) => {
  return (
    <div id="Location" style={style}>
      <LazyLoad height={780} once>
        <LocationCore {...props} />
      </LazyLoad>
    </div>
  );
};

export default Location;
