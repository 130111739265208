import React from 'react';
import { IconProps } from './types';

const SearchIcon: React.FC<IconProps> = ({
  color = '#072f87',
  height = 18.367,
  width = 18.367,
  style
}) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="img"
      width={width}
      height={height}
      style={style}
      viewBox="0 0 18.367 18.367"
    >
      <path
        d="M18.367,16.75l-3.812-3.812a7.909,7.909,0,0,0,1.617-4.852A8.036,8.036,0,0,0,8.086,0,8.036,8.036,0,0,0,0,8.086a8.036,8.036,0,0,0,8.086,8.086,7.909,7.909,0,0,0,4.852-1.617l3.812,3.812ZM2.31,8.086A5.72,5.72,0,0,1,8.086,2.31a5.72,5.72,0,0,1,5.776,5.776,5.72,5.72,0,0,1-5.776,5.776A5.72,5.72,0,0,1,2.31,8.086Z"
        fill={color}
      />
    </svg>
  );
};

export default SearchIcon;
