import React from 'react';
import classnames from 'classnames';
import './big-text.css';

export interface BigTextProps {
  style?: React.CSSProperties;
  value: string;
  white?: boolean;
  thin?: boolean;
}

const BigText: React.FC<BigTextProps> = ({ style, value, thin, white }) => {
  const className = classnames({
    BigText: true,
    BigText__thin: thin, // eslint-disable-line @typescript-eslint/camelcase
    BigText__white: white // eslint-disable-line @typescript-eslint/camelcase
  });
  return (
    <div className={className} style={style}>
      {value}
    </div>
  );
};

export default BigText;
