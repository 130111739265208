import React from 'react';
import BigText from '../../components/big-text';
import DotNav from '../../components/dot-nav';
import HorizontalScroll from '../../components/horizontal-scroll';
import { NavItem } from '../../utils/types';
import { useMedia } from '../../utils/hooks';
import './promotions.css';
import { graphql, useStaticQuery } from 'gatsby';
import { PROMOTIONS } from './promotions';

export interface PromotionsProps {
  number: string;
  style?: React.CSSProperties;
  navItems: Array<NavItem>;
  onNavItemSelected: (index: number) => void;
}

interface PromoProps {
  title: string;
  img: string;
  url?: string;
  onClick: (url?: string) => void;
}

const PromoBase: React.FC<PromoProps> = ({ title, img, url, onClick }) => {
  const handleClick = () => onClick(url);
  return (
    <div
      className="Promotion-item"
      key={title}
      role="button"
      onClick={handleClick}
      style={{ width: 425 }}
    >
      <img
        className="img"
        src={img}
        style={{ cursor: 'pointer', width: 425 }}
      />
    </div>
  );
};
const Promo: React.FC<PromoProps> = React.memo(PromoBase);

const Promotions: React.FC<PromotionsProps> = ({
  style,
  number,
  navItems,
  onNavItemSelected
}) => {
  const { images } = useStaticQuery(graphql`
    query {
      images: allFile(filter: { relativeDirectory: { eq: "promotions" } }) {
        nodes {
          relativePath
          publicURL
        }
      }
    }
  `);
  const imageMap = images.nodes.reduce((res, item) => {
    res.set(item.relativePath.replace('promotions/', ''), item.publicURL);
    return res;
  }, new Map<string, string>());

  const scrollOptions = useMedia(
    ['(min-width:1145px)', '(min-width:495px)', '(min-width:0px)'],
    [
      { slidesToShow: 2, centerMode: false },
      { slidesToShow: 1, centerMode: false },
      { slidesToShow: 1, centerMode: false }
    ],
    {
      slidesToShow: 1
    }
  );
  const verticalBar = useMedia(['(max-width:622px)'], [false], true);

  const NOW = Date.now();
  const promotions = PROMOTIONS.filter(
    i => i.startDate.valueOf() <= NOW && i.endDate.valueOf() > NOW
  );

  function handleNav(url?: string) {
    if (url) {
      window.location.href = url;
    }
  }

  const promotionList = promotions.map(promo => (
    <Promo
      key={promo.title}
      title={promo.title}
      img={imageMap.get(promo.img)}
      url={promo.url}
      onClick={handleNav}
    />
  ));

  return (
    <div id="Promotions" style={style}>
      <div className="Promotions-background-text">
        <BigText
          value={number}
          thin={true}
          white={false}
          style={{ position: 'absolute', right: '-20px', top: '50px' }}
        />
      </div>
      <div className="Promotions-header">
        <div className="Promotions-header__title">
          <div className="Promotions-header__top">CHECK OUT OUR</div>
          <div className="Promotions-header__main">Current Promotions</div>
        </div>
        <DotNav
          selected={parseInt(number, 10) - 1}
          items={navItems}
          vertical={verticalBar}
          dark={true}
          onChange={onNavItemSelected}
          style={
            verticalBar ? { marginLeft: '-30px', position: 'absolute' } : {}
          }
        />
      </div>
      <div className="Promotions-slider">
        <div className="Promotions-slider__content">
          {promotions.length === 0 && (
            <div className="Promotions__indicator-text">
              New Promotions Starting Soon
            </div>
          )}
          <HorizontalScroll
            key={`${scrollOptions.slidesToShow}-${promotions.length}`}
            variableWidth={false}
            autoplay={false}
            centerMode={scrollOptions.centerMode}
            slidesToShow={Math.min(
              scrollOptions.slidesToShow,
              promotions.length
            )}
          >
            {promotionList}
          </HorizontalScroll>
        </div>
      </div>
    </div>
  );
};

export default Promotions;
