const defaultNumber = '8336063467';

const validNumbers = [
  '7044920990',
  '9804226156',
  '9808002834',
  '8438195339',
  '9808003531',
  '9808801986',
  '8645641762',
  '3367922940',
  '8648499075',
  '8649005964',
  '3367924092',
  '9105487551',
  '8545290894',
  '8439703396',
  '9105487560',
  '8648499066',
  '9102270081',
  '8434384819',
  '3367909346',
  '3367922924',
  '8542073422',
];

function stripNumber(pn: string) {
  if (!pn) return '';
  return pn.replace(/(\(|\)|-|\s)/g, '');
}

export function formatPhoneNumber(pn: string) {
  if (!pn) return '';
  return `(${pn.slice(0, 3)}) ${pn.slice(3, 6)}-${pn.slice(6, 10)}`;
}

export function checkPhoneNumber(pn = '') {
  return formatPhoneNumber(
    validNumbers.includes(stripNumber(pn)) ? pn : defaultNumber
  );
}
