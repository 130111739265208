import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import './video-button.css';

export interface VideoButtonProps {
  style?: React.CSSProperties;
  videoId: string;
  title?: string;
  text?: string;
}

function noop() {
  /* NOOP */
}

const VideoButton: React.FC<VideoButtonProps> = ({
  style,
  videoId,
  title = 'Watch Video',
  text
}) => {
  const [videoOpen, setVideoOpen] = useState<boolean>(false);
  const closeVideo = () => setVideoOpen(false);
  const openVideo = () => setVideoOpen(true);
  return (
    <div
      className="VideoButton"
      style={style}
      role="button"
      onClick={videoOpen ? noop : openVideo}
      title={title}
    >
      <ModalVideo
        channel="youtube"
        isOpen={videoOpen}
        videoId={videoId}
        onClose={closeVideo}
      />
      <div className="VideoButton__text">{text}</div>
      <div className="VideoButton__arrow" />
    </div>
  );
};

export default VideoButton;
