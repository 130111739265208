import React from 'react';
import BigText from '../../components/big-text';
import DotNav from '../../components/dot-nav';
import { NavItem } from '../../utils/types';
import './sustainability.css';

export interface SustainabilityProps {
  number: string;
  style?: React.CSSProperties;
  navItems: Array<NavItem>;
  onNavItemSelected: (index: number) => void;
}

const Sustainability: React.FC<SustainabilityProps> = ({
  number,
  style,
  navItems,
  onNavItemSelected
}) => {
  return (
    <div id="Sustainability" style={style}>
      <div className="Sustainability-background-text">
        <BigText
          value={number}
          thin={true}
          white={false}
          style={{ position: 'absolute', right: '-20px', top: '50px' }}
        />
      </div>
      <div className="Sustainability-header">
        <DotNav
          selected={parseInt(number, 10) - 1}
          items={navItems}
          dark={true}
          vertical={true}
          onChange={onNavItemSelected}
          style={{ marginLeft: '-30px', position: 'absolute' }}
        />
        <div className="Sustainability-title">
          <div className="Sustainability-title__top">Learn More About Our</div>
          <div className="Sustainability-title__main">
            Sustainability Practices
          </div>
        </div>
      </div>
      <div className="Sustainability-main">
        <div className="Sustainability-details">
          <div className="Sustainability-details_column">
            <div className="Sustainability-details_header">
              <div className="Sustainability-details_icon">
                <img src="https://aaacdndev.blob.core.windows.net/wordpress/fins/assets/newfins-sustainability-column1icon.png" />
              </div>
              <h5 className="Sustainability-details__statement">
                We Use Less Water in Our Car Wash
              </h5>
            </div>
            <p className="Sustainability-details__paragraph">
              Commercial car washing is the “greenest” way to wash your car. At
              Fins, we recycle our water to use less than a third of the amount
              that you use in your driveway when washing your car at home.
            </p>
          </div>
          <div className="Sustainability-details_column">
            <div className="Sustainability-details_header">
              <div className="Sustainability-details_icon">
                <img src="https://aaacdndev.blob.core.windows.net/wordpress/fins/assets/newfins-sustainability-column2icon.png" />
              </div>
              <h5 className="Sustainability-details__statement">
                We Keep Pollutants Out of Waterways
              </h5>
            </div>
            <p className="Sustainability-details__paragraph">
              The grime on your car is made up of a lot of nasty stuff that you
              don&apos;t want running into storm drains and out to the ocean. At
              Fins, we separate hazardous substances from rinse water and send
              them where they belong – straight to the sewage treatment plant to
              be disposed of safely.
            </p>
          </div>
          <div className="Sustainability-details_column">
            <div className="Sustainability-details_header">
              <div className="Sustainability-details_icon">
                <img src="https://aaacdndev.blob.core.windows.net/wordpress/fins/assets/newfins-sustainability-column3icon.png" />
              </div>
              <h5 className="Sustainability-details__statement">
                Automakers Don’t Recommend Home Washing
              </h5>
            </div>
            <p className="Sustainability-details__paragraph">
              Over time, abrasive particles embedded in dirt can take the shine
              off the finish when you hand wash your car. Fins has specialized
              equipment and detergents, along up with high pressure rinses, that
              keep your car looking showroom spotless without the potential
              damage hand washing can bring.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sustainability;
