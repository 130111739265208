import React from 'react';
import { IconProps } from './types';

const AAAIcon: React.FC<IconProps> = ({
  color = '#fff',
  secondaryColor = '#94cc00',
  height = 55.846,
  width = 92.716,
  style = {}
}) => {
  return (
    <svg
      className="AAAIcon"
      aria-hidden="true"
      focusable="false"
      role="img"
      style={{ width, height, ...style }}
      viewBox="0 0 92.716 55.846"
    >
      <g transform="translate(-170.329 -257.363)">
        <g transform="translate(170.329 257.363)">
          <path
            d="M353.839,259.576c-2.691-4.663-17-1.705-34.793,6.486a23.573,23.573,0,0,1,2.567,2.278c13.659-5.759,24.242-7.558,26.394-3.831,2.543,4.4-7.457,15.126-23.369,25.836l.18.192C344.314,277.85,356.852,264.794,353.839,259.576Z"
            transform="translate(-261.569 -257.363)"
            fill={color}
          />
          <path
            d="M182.754,368.911c-1.57-2.719,1.654-7.853,8.093-13.9h0q-.773-.277-1.518-.577l-.085-.034c-.506-.206-1-.42-1.489-.643h0c-7.744,7.13-11.67,13.206-9.837,16.38,2.15,3.723,11.709,2.586,24.54-2.2l-.146-.208C192.055,371.3,184.532,371.991,182.754,368.911Z"
            transform="translate(-174.712 -316.499)"
            fill={color}
          />
          <g transform="translate(0 1.236)">
            <path
              d="M170.329,280.541c0-11.035,14.462-19.98,32.3-19.98s32.3,8.945,32.3,19.98-14.463,19.977-32.3,19.977S170.329,291.573,170.329,280.541ZM188.64,265l6.066,20.811h.032l6.474-23.493A35.793,35.793,0,0,0,188.64,265Zm12.234,8.619-3.336,12.192h6.736Zm9.564,12.192,5.774-20.979a36.064,36.064,0,0,0-12.664-2.524l6.867,23.5Zm-21.915,0-3.34-12.192-3.337,12.192Zm38.3,2.451a13.581,13.581,0,0,0,2.5-7.718c0-5.339-3.354-10.143-8.7-13.48Zm-6.858-2.451-3.4-12.192-3.316,12.192Zm.682,2.442h-8.062l-.7,2.584,1.843,6.307A28.953,28.953,0,0,0,222,293.1Zm-29.026,8.869-2.429-8.869h-8.015l-.682,2.495a26.734,26.734,0,0,0,11.115,6.417Zm13.334-8.869H196.87l-2.631,9.617a38.053,38.053,0,0,0,8.394.926,38.805,38.805,0,0,0,5.166-.346ZM184.119,267.4c-5.041,3.321-8.183,7.981-8.183,13.145a13.567,13.567,0,0,0,2.455,7.644Z"
              transform="translate(-170.329 -260.561)"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AAAIcon;
